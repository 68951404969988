import { useQuery } from "@apollo/client"
import { GET_ASISTENCIA_TYPES } from "./queries"
import { IAsistenciaType } from "../../interfaces"

export const useAsistenciaTypes = () => {
  const { data, error, loading } = useQuery(GET_ASISTENCIA_TYPES)

  return {
    data: (data && data.getAsistenciaTypes) ? data.getAsistenciaTypes as IAsistenciaType[] : null,
    error,
    loading
  }
}