import { AppBar, Stack, Toolbar, Typography, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const StyledToolbar = styled(Toolbar)({
    display: 'flex',
    justifyContent: 'space-between'
})

export const Header = () => {
    const navigate = useNavigate()

    const handleBackButton = () => {
        if (navigate.length > 1) navigate(-1)
        else navigate('/')
    }

    return (
        <AppBar position='sticky'>
            <StyledToolbar>
                <Stack direction='row' alignItems='center' style={{ cursor: 'pointer' }} onClick={handleBackButton}>
                    <Stack direction='row' gap={1} alignItems='end'>
                        <Typography variant='h6' color='white'>Mi Cuadrilla</Typography>
                        <Typography variant='body2' color='whitesmoke'>v {process.env.REACT_APP_VERSION}{process.env.REACT_APP_STAGE === 'dev' ? '-dev' : ''}</Typography>
                    </Stack>
                </Stack>
            </StyledToolbar>
        </AppBar >
    )
}