import { gql } from "@apollo/client";

export const GET_CUADRILLAS = gql`
query GetCuadrillas {
  getCuadrillas {
    id
    name
    color
  }
}
`