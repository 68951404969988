import { gql } from "@apollo/client";

export const ADD_COSTALERO = gql`
mutation AddCostalero($name: String!, $cuadrilla: Int!) {
  addCostalero(name: $name, cuadrilla: $cuadrilla) {
    id
    name
  }
}
`

export const REMOVE_COSTALERO = gql`
mutation RemoveCostalero($id: Int!) {
  removeCostalero(id: $id) {
    id
    name
  }
}
`