import { useState } from "react"
import { useAddCostalero } from "../api/costaleros/costaleros"
import { Button, Dialog, DialogActions, DialogContent, Fab, TextField, Typography } from "@mui/material"
import { Add } from "@mui/icons-material"

interface IAddCostaleroButtonProps {
  cuadrilla: number
}

export const AddCostaleroButton = ({ cuadrilla }: IAddCostaleroButtonProps) => {
  const { add } = useAddCostalero(cuadrilla)
  const [costaleroName, setCostaleroName] = useState('')
  const [loading, setLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const closeDialog = () => {
    if (loading) return
    setOpenDialog(false)
    setCostaleroName('')
  }

  const addCostalero = async () => {
    setLoading(true)
    await add(costaleroName)
    setLoading(false)
    setCostaleroName('')
  }

  return (
    <>
      <Fab color='primary' style={{ position: 'fixed', bottom: '1rem', right: '1rem' }} onClick={() => setOpenDialog(true)}>
        <Add color='secondary' />
      </Fab>
      <Dialog
        open={openDialog}
        onClose={closeDialog}
        disableRestoreFocus
        fullWidth
      >
        <DialogContent style={{ paddingTop: '1rem' }}>
          <Typography variant='h5' style={{ marginBottom: '1rem' }}>Añadir Costalero</Typography>
          <TextField
            disabled={loading}
            autoFocus
            label='Nombre'
            value={costaleroName}
            fullWidth
            onChange={(event) => setCostaleroName(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={closeDialog}>Cancelar</Button>
          <Button variant='contained' disabled={loading || costaleroName.trim() === ''} onClick={addCostalero}>Añadir</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}