import { gql } from "@apollo/client";

export const GET_ASISTENCIA_TYPES = gql`
query GetAsistenciaTypes {
  getAsistenciaTypes {
    id
    name
    color
  }
}
`