import { useMutation, useQuery } from "@apollo/client"
import { GET_COSTALEROS } from "./queries"
import { ICostalero } from "../../interfaces"
import { ADD_COSTALERO, REMOVE_COSTALERO } from "./mutations"

export const useCostaleros = (cuadrilla: number) => {
  const { data, error, loading } = useQuery(GET_COSTALEROS, { variables: { cuadrilla } })
  const [addCostalero] = useMutation(ADD_COSTALERO, { refetchQueries: [{ query: GET_COSTALEROS, variables: { cuadrilla } }] })
  const [removeCostalero] = useMutation(REMOVE_COSTALERO, { refetchQueries: [{ query: GET_COSTALEROS, variables: { cuadrilla } }] })

  const add = async (name: string) => {
    await addCostalero({ variables: { name, cuadrilla } })
  }

  const remove = async (id: number) => {
    await removeCostalero({ variables: { id } })
  }

  return {
    data: (data && data.getCostaleros) ? data.getCostaleros as ICostalero[] : null,
    error,
    loading,

    add,
    remove,
  }
}

export const useAddCostalero = (cuadrilla: number) => {
  const [addCostalero] = useMutation(ADD_COSTALERO, { refetchQueries: [{ query: GET_COSTALEROS, variables: { cuadrilla } }] })

  const add = async (name: string) => {
    await addCostalero({ variables: { name, cuadrilla } })
  }

  return {
    add
  }
}