import { createTheme } from "@mui/material"
import { grey } from "@mui/material/colors"

export const primaryColor = '#fff'
export const secondaryColor = '#272727'

export const webTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: primaryColor,
            contrastText: "#fff"
        },
        secondary: {
            main: secondaryColor,
            contrastText: "#fff"
        },
        info: {
            main: grey[100]
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    color: secondaryColor
                }
            }
        }
    }
})
