import { Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Stack, Typography } from "@mui/material"
import { IAttendance } from "../interfaces"
import { Close } from "@mui/icons-material"
import { useState } from "react"
import { useAsistenciaTypes } from "../api/asistenciaTypes/asistenciaTypes"
import { CheckboxBox } from "./CheckboxBox"

interface IAttendanceCheckBoxProps {
  attendance: IAttendance
  canDelete: boolean
  removeCostalero: (id: number) => Promise<void>
  addAsistencia: (costalero: number, type: number) => Promise<void>
}

export const AttendanceCheckBox = ({ attendance, canDelete, removeCostalero, addAsistencia }: IAttendanceCheckBoxProps) => {
  const { data: asistenciaTypes } = useAsistenciaTypes()
  const [openDialog, setOpenDialog] = useState(false)
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const hanldeRemove = async () => {
    setLoading(true)
    await removeCostalero(attendance.costalero)
    setOpenRemoveDialog(false)
    setLoading(false)
  }

  const handleAddAsistencia = async (type: number) => {
    setLoading(true)
    await addAsistencia(attendance.costalero, type)
    setOpenDialog(false)
    setLoading(false)
  }

  const handleClose = () => {
    if (loading) return
    setOpenDialog(false)
    setOpenRemoveDialog(false)
  }

  if (!asistenciaTypes) return

  return (
    <>
      <Grid container alignItems='center'>
        <Grid item xs={11}>
          <Stack direction='row' gap='1rem' alignItems='center' sx={{ cursor: 'pointer', py: { xs: 1, md: 2 } }} onClick={() => setOpenDialog(true)}>
            <CheckboxBox color={attendance.color ?? ''} />
            <Typography variant="h6" sx={{ fontWeight: 600 }}>{attendance.name}</Typography>
          </Stack>
        </Grid>
        {canDelete &&
          <Grid item xs={1}>
            <IconButton onClick={() => setOpenRemoveDialog(true)}><Close color='error' /></IconButton>
          </Grid>
        }
      </Grid>



      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth
      >
        <DialogContent>
          <Typography variant='h5' style={{ marginBottom: '1rem' }}>{attendance.name}</Typography>
          <Stack direction='column' gap='1rem'>
            {asistenciaTypes.map((item) => (
              <Stack
                key={item.id}
                direction='row'
                gap='1rem'
                alignItems='center'
                onClick={() => handleAddAsistencia(item.id)}
                style={{ cursor: 'pointer', opacity: loading ? '.5' : '1' }}
              >
                <CheckboxBox color={item.color} />
                <Typography variant='h6'>{item.name}</Typography>
              </Stack>
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose}>Cancelar</Button>
        </DialogActions>
      </Dialog>



      <Dialog
        open={openRemoveDialog}
        onClose={handleClose}
        fullWidth
      >
        <DialogContent><Typography variant='h5'>¿Quieres eliminar a {attendance.name}?</Typography></DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose}>Cancelar</Button>
          <Button variant='contained' disabled={loading} onClick={hanldeRemove}>Eliminar</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}