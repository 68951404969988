import { gql } from "@apollo/client";

export const GET_ASISTENCIAS = gql`
query GetAsistencias($asistenciaDate: String!) {
  getAsistencias(asistenciaDate: $asistenciaDate) {
    id
    costalero
    type
    asist_date
  }
}
`