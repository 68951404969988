import { gql } from "@apollo/client";

export const ADD_ASISTENCIA = gql`
mutation AddAsistencia($costalero: Int!, $type: Int!, $asistenciaDate: String!) {
  addAsistencia(costalero: $costalero, type: $type, asistenciaDate: $asistenciaDate) {
    id
    costalero
    type
    asist_date
  }
}
`

export const MODIFY_ASISTENCIA = gql`
mutation ModifyAsistencia($id: Int!, $type: Int!) {
  modifyAsistencia(id: $id, type: $type) {
    id
    costalero
    type
    asist_date
  }
}
`