import { useMutation, useQuery } from "@apollo/client"
import { GET_ASISTENCIAS } from "./queries"
import { IAsistencia } from "../../interfaces"
import { ADD_ASISTENCIA, MODIFY_ASISTENCIA } from "./mutations"

export const useAsistencias = (asistenciaDate: string) => {
  const { data, error, loading } = useQuery(GET_ASISTENCIAS, { variables: { asistenciaDate } })
  const [addAsistencia] = useMutation(ADD_ASISTENCIA, { refetchQueries: [{ query: GET_ASISTENCIAS, variables: { asistenciaDate } }] })
  const [modifyAsistencia] = useMutation(MODIFY_ASISTENCIA, { refetchQueries: [{ query: GET_ASISTENCIAS, variables: { asistenciaDate } }] })

  const add = async (costalero: number, type: number) => {
    await addAsistencia({ variables: { costalero, type, asistenciaDate } })
  }

  const modify = async (id: number, type: number) => {
    await modifyAsistencia({ variables: { id, type } })
  }

  return {
    data: (data && data.getAsistencias) ? data.getAsistencias as IAsistencia[] : null,
    error,
    loading,

    add,
    modify,
  }
}