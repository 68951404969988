import { useParams } from "react-router-dom"
import { useCostaleros } from "../api/costaleros/costaleros"
import { useAsistenciaTypes } from "../api/asistenciaTypes/asistenciaTypes"
import { useAsistencias } from "../api/asistencias/asistencias"
import { getToday } from "../utils/getToday"
import { CenterLoading } from "../components/common/CenterLoading"
import { Container, IconButton, Stack, Typography } from "@mui/material"
import { AttendanceCheckBox } from "../components/AttendanceCheckBox"
import { AddCostaleroButton } from "../components/AddCostaleroButton"
import { Delete, DeleteOutline } from "@mui/icons-material"
import { useState } from "react"

export const Costaleros = () => {
  const { cuadrilla } = useParams()
  const today = getToday()
  const { data: asistenciaTypes, loading: asistenciaTypesLoading } = useAsistenciaTypes()
  const { data: costaleros, loading: costalerosLoading, remove: removeCostalero } = useCostaleros(parseInt(cuadrilla ?? '0'))
  const { data: asistencias, loading: asistenciasLoading, add: addAsistencia, modify: modifyAsistencia } = useAsistencias(today)

  const [canDelete, setCanDelete] = useState(false)

  if (
    asistenciaTypesLoading || !asistenciaTypes ||
    costalerosLoading || !costaleros ||
    asistenciasLoading || !asistencias
  ) return (
    <CenterLoading label={`Cargando información...`} />
  )

  const processData = () => {
    const allData = costaleros.map((costalero) => {
      const attendance = asistencias.find((asistencia) => asistencia.costalero === costalero.id)
      const type = asistenciaTypes.find((aType) => aType.id === attendance?.type)

      return {
        costalero: costalero.id,
        name: costalero.name,
        asistencia: attendance?.id,
        asist_date: attendance?.asist_date,
        type: type?.id,
        color: type?.color
      }
    })

    return allData
  }

  const handleAddAsistencia = async (costalero: number, type: number) => {
    const asistencia = processData().find((item) => item.costalero === costalero)
    if (asistencia && asistencia.asistencia) {
      await modifyAsistencia(asistencia.asistencia, type)
    } else {
      await addAsistencia(costalero, type)
    }
  }

  return (
    <Container style={{ paddingTop: '1rem' }}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant="h6">{today}</Typography>
        <IconButton onClick={() => setCanDelete(!canDelete)}>{canDelete ? <Delete color='error' /> : <DeleteOutline color='error' />}</IconButton>
      </Stack>
      <Stack paddingTop='1rem' paddingBottom='4rem' >
        {processData().map(item => (
          <AttendanceCheckBox key={item.costalero} attendance={item} canDelete={canDelete} removeCostalero={removeCostalero} addAsistencia={handleAddAsistencia} />
        ))}
      </Stack>
      <AddCostaleroButton cuadrilla={parseInt(cuadrilla ?? '0')} />
    </Container>
  )
}