import { Stack, Typography } from "@mui/material"
import { ICuadrilla } from "../interfaces"

export const CuadrillaBox = (props: ICuadrilla) => (
  <Stack
    padding='.5rem'
    borderRadius='10px'
    bgcolor={props.color}
    minWidth='100px'
    minHeight='100px'
    justifyContent='center'
    alignItems='center'
    style={{ cursor: 'pointer' }}
  >
    <Typography variant="h6" color='white'>{props.name}</Typography>
  </Stack>
)