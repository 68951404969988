import { useQuery } from "@apollo/client"
import { GET_CUADRILLAS } from "./queries"
import { ICuadrilla } from "../../interfaces"

export const useCuadrillas = () => {
  const { data, error, loading } = useQuery(GET_CUADRILLAS)

  return {
    data: (data && data.getCuadrillas) ? data.getCuadrillas as ICuadrilla[] : null,
    error,
    loading
  }
}