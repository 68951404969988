import { Grid, Stack, Typography } from "@mui/material"
import { CuadrillaBox } from "../components/CuadrillaBox"
import { useCuadrillas } from "../api/cuadrillas/cuadrillas"
import { CenterLoading } from "../components/common/CenterLoading"
import { Link } from "react-router-dom"

export const Home = () => {

  const { data: cuadrillas, loading } = useCuadrillas()

  if (loading || !cuadrillas) return (
    <CenterLoading label="Cargando cuadrillas..." />
  )

  return (
    <Stack height='100%' width='100%' alignItems='center' justifyContent='center' gap='2rem'>
      <Typography variant="h6">Elige una cuadrilla</Typography>
      <Grid container spacing={2} alignItems='stretch' justifyContent='center'>
        {cuadrillas.map(item => {
          if (process.env.REACT_APP_STAGE !== 'dev' && item.name.toLowerCase().includes('test')) return <div key={item.name}></div>

          return (
            < Grid item key={item.name} >
              <Link style={{ textDecoration: 'none' }} to={`/costaleros/${item.id}`}>
                <CuadrillaBox {...item} />
              </Link>
            </Grid>
          )
        })}
      </Grid>
    </Stack >
  )
}