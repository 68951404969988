import { Routes, Route } from "react-router-dom"
import { Home } from "./pages/Home"
import { Costaleros } from "./pages/Costaleros"

export const MyRoutes = () => {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/costaleros/:cuadrilla' element={<Costaleros />} />
        </Routes>
    )
}